<template>
  <div class="appointment-picker" :class="[{ compact: compact }, 'ap-' + displayStyle]">
    <div v-show="currentView == 'calendar' || displayStyle == 'inline'" class="calendar">
      <div class="flex justify-center items-center gap-6">
        <div class="month-chevron" @click="prevMonth">
          <Icon type="chevron-left" fa-style="fas" />
        </div>
        <div class="flex gap-2 w-[120px] justify-center">
          <div>{{ dayjs(calendarMonthYear).format('MMMM') }}</div>
          <div>{{ dayjs(calendarMonthYear).format('YYYY') }}</div>
        </div>
        <div class="month-chevron" @click="nextMonth">
          <Icon type="chevron-right" fa-style="fas" />
        </div>
      </div>
      <div class="calendar-grid">
        <div v-for="dow in weekdays" :key="dow" class="calendar-grid-header">{{ dow }}</div>
        <div
          v-for="day in daysInMonth"
          :key="day"
          v-tippy="day.isClosed ? 'Closed' : ''"
          class="grid-day"
          :class="[
            {
              available: day.isAvailable,
              today: dayjs(day.date).isToday(),
              selected: day.day && dayjs(day.date).format('YYYY-MM-DD') == dayjs(selectedDate).format('YYYY-MM-DD')
            },
            day.trafficLight
          ]"
          @click.stop="onDayClick(day)"
        >
          <div v-if="day.isClosed" class="grid-day-closed"></div>
          {{ day.day ? day.day : '' }}
        </div>
      </div>
    </div>

    <div
      v-show="currentView == 'time-picker' || (displayStyle == 'inline' && selectedTime) || (displayStyle == 'inline' && !selectedDate)"
      class="time-slots"
      :class="{ 'time-slots_tiled': availableTimeSlots.length > 5 }"
    >
      <div v-if="displayStyle != 'inline'" class="time-slot-back-btn" @click="changeView('calendar')">
        <Icon type="chevron-left" fa-style="fas" />
        <div>{{ dayjs(selectedDate).format('Do MMM YYYY') }}</div>
      </div>

      <div v-if="availableTimeSlots.length && selectedDate" class="time-slots-wrapper">
        <div
          v-for="slot in availableTimeSlots"
          :key="slot"
          class="time-slot"
          :class="{ selected: slot.timeStart == selectedTime }"
          @click="onTimeSlotClick(slot)"
        >
          <div class="time" :class="{ disabled: !slot.available }" @click="onConfirmClick(slot)">
            {{ dayjs('2000-01-01 ' + slot.timeStart).format('HH:mm') }}
          </div>
        </div>
      </div>

      <div v-if="!selectedDate">
        <div class="text-quaternary">Select a date</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Icon from '@/components/icon/Icon.vue'

import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
dayjs.extend(weekday)

import { computed, watch } from 'vue'

export default {
  components: { Icon },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    availabilityMode: {
      type: String,
      default: 'available' // available, all
    },
    availableDates: {
      type: Array,
      default: () => []
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss' // ISO 8601 = YYYY-MM-DD HH:mm:ss
    },
    showUnavailableTimes: {
      type: Boolean,
      default: true
    },
    trafficLight: {
      type: Boolean,
      default: true
    },
    displayStyle: {
      type: String,
      default: 'paged' // paged, inline
    },
    compact: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue', 'onSelect', 'dateRangeUpdated', 'onConfirm'],
  setup(props, { emit }) {
    const currentView = ref('calendar')

    const selectedDate = ref(null)
    const selectedTime = ref(null)
    const availableTimeSlots = ref([])

    const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

    const calendarMonthYear = ref(dayjs())

    function emitDateRangeChange() {
      emit('dateRangeUpdated', {
        start: dayjs(calendarMonthYear.value).startOf('month').format('YYYY-MM-DD'),
        end: dayjs(calendarMonthYear.value).endOf('month').format('YYYY-MM-DD')
      })
    }

    const daysInMonth = computed(() => {
      let days = []
      let startDay = dayjs(calendarMonthYear.value).startOf('month').day()
      // If startDay is 0 (sunday), set it to 7
      if (startDay == 0) {
        startDay = 7
      }
      let totalDays = dayjs(calendarMonthYear.value).daysInMonth()

      for (let i = 1; i < totalDays + startDay; i++) {
        let date = dayjs(dayjs(calendarMonthYear.value).format('YYYY-MM-') + (i - startDay + 1))
        let isAvailable = false
        let timeSlots = []
        let trafficLight = null
        let availableSlotCount = null

        let availabilityDate = props.availableDates.find(d => dayjs(d.date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))

        if (availabilityDate && availabilityDate?.isAcceptingAppointments) {
          availabilityDate.slots.forEach(s => {
            availableSlotCount += s.available
          })

          if (availableSlotCount) {
            isAvailable = true
          }

          if (props.showUnavailableTimes) {
            timeSlots = availabilityDate.slots
          } else {
            timeSlots = availabilityDate.slots.filter(s => s.available)
          }

          // Calculate traffic light. calc: maxSlots = 100%, unavailableSlotsCount = x, x / 100 * 100
          if (availabilityDate?.slots?.length > 0 && props.trafficLight && date >= dayjs().subtract(1, 'days')) {
            if (availableSlotCount <= 0) {
              trafficLight = null
            } else if (availableSlotCount == 1) {
              trafficLight = 'danger'
            } else if (availableSlotCount == 2) {
              trafficLight = 'warning'
            } else {
              trafficLight = 'success'
            }
          }
        }

        // loop through slots and set isAvailable to false if in the past
        timeSlots.forEach(slot => {
          if (dayjs(date.format('YYYY-MM-DD') + ' ' + slot.timeStart) < dayjs()) {
            slot.available = false
          }
        })

        if (i >= startDay) {
          days.push({
            day: i - startDay + 1,
            date: date,
            isClosed: availabilityDate?.isClosed ?? false,
            isAvailable: date >= dayjs().subtract(1, 'days') && isAvailable,
            maxSlots: availabilityDate?.maxAppointments ?? null,
            timeSlots: timeSlots,
            availableSlotCount: availableSlotCount,
            trafficLight: trafficLight,
            dataFetched: availabilityDate ? true : false
          })
        } else {
          // Date Padding
          days.push({
            day: null,
            date: null,
            isAvailable: false,
            timeSlots: []
          })
        }
      }
      return days
    })

    emitDateRangeChange()

    // If modelValue is set, set the monthYear to the modelValue
    if (props.modelValue) {
      calendarMonthYear.value = dayjs(props.modelValue)
      emitDateRangeChange()
    }

    function nextMonth() {
      calendarMonthYear.value = dayjs(calendarMonthYear.value).add(1, 'month')
      emitDateRangeChange()
    }
    function prevMonth() {
      calendarMonthYear.value = dayjs(calendarMonthYear.value).subtract(1, 'month')
      emitDateRangeChange()
    }

    function changeView(view) {
      currentView.value = view
      selectedTime.value = props.modelValue
      emit('update:modelValue', props.modelValue)
    }

    function onDayClick(day) {
      if ((props.availabilityMode == 'available' && day.isAvailable) || props.availabilityMode == 'any') {
        selectedDate.value = day.date
        availableTimeSlots.value = day.timeSlots
        changeView('time-picker')
      }
    }

    function onTimeSlotClick(slot) {
      if (slot.available) {
        selectedTime.value = slot.timeStart
      }
    }

    function onConfirmClick(slot) {
      if (!slot.available) return
      emit('update:modelValue', dayjs(dayjs(selectedDate.value).format('YYYY-MM-DD') + ' ' + slot.timeStart).format(props.dateFormat))
      emit('onConfirm')
      return
    }

    const appointmentMethods = computed(() => {
      let methods = [
        {
          method: 'physical',
          icon: 'map-marker',
          tippy: 'At Dealership',
          available: false
        },
        {
          method: 'phone',
          icon: 'phone',
          tippy: 'Phone Call',
          available: false
        },
        {
          method: 'video',
          icon: 'video',
          tippy: 'Video Call',
          available: false
        }
      ]

      if (props.availabilityMode == 'any') {
        methods.forEach(m => {
          m.available = true
        })
      } else {
        // Get data using selectedDate on availableDates
        let availabilityDate = props.availableDates.find(d => dayjs(d.date).isSame(selectedDate.value, 'day'))

        // Set methods to available if they are allowed in any slot
        methods.forEach(m => {
          m.available = availabilityDate?.availableMethods[m.method] || false
        })
      }

      return methods
    })

    // watch for changes in availableDates. If change, set selectedDate to null
    watch(
      () => props.availableDates,
      () => {
        selectedDate.value = null
        selectedTime.value = null
        availableTimeSlots.value = []
      }
    )

    return {
      currentView,
      changeView,
      selectedDate,
      selectedTime,
      availableTimeSlots,
      onDayClick,
      onTimeSlotClick,
      onConfirmClick,
      weekdays,
      calendarMonthYear,
      daysInMonth,
      nextMonth,
      prevMonth,
      appointmentMethods
    }
  }
}

// IDEA
// Be able to define a default date, else today
// Be able to accept 2 arrays
// - Array of available dates
// - Array of unavailable dates. This should override the available dates

// Props for visuals
// - primary color
// - enableMonthYearDropdown

// Styling
// - Animated transition between months
// - visual feedback for time slot loading
</script>

<style scoped>
.appointment-picker {
  user-select: none;
  /* max-width: max-content; */
  overflow: hidden;
}
.appointment-picker.ap-inline {
  display: flex;
  column-gap: var(--s-8);
  width: 100%;
}

.calendar {
  max-width: max-content;
}

.month-chevron {
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--extra-light);
}
.compact .month-chevron {
  height: 30px;
  width: 30px;
  font-size: var(--text-sm);
}
.month-chevron:hover {
  color: var(--fg-brand);
  background-color: var(--bg-secondary);
}
.month-chevron.disabled {
  color: var(--text-secondary);
  background-color: transparent;
  cursor: not-allowed;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: var(--s-2);
  text-align: center;
}
.compact .calendar-grid {
  gap: var(--s-1);
}

.calendar-grid-header {
  font-size: var(--text-sm);
  text-align: center;
  margin-top: var(--s-4);
  color: var(--text-secondary);
}

.grid-day {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: var(--text-md);
  color: var(--text-secondary);
  position: relative;
  transition: all 0.2s;
}
.compact .grid-day {
  height: 30px;
  width: 30px;
  font-size: var(--text-sm);
}
.grid-day-closed::after {
  content: '';
  display: block;
  width: 60%;
  height: 1px; /* Adjust the thickness of the line */
  background-color: rgb(142, 142, 142); /* Color of the line */
  position: absolute;
  top: 50%; /* Adjust this to position the line correctly */
  left: 7px;
  transform: rotate(45deg); /* Rotates the line 45 degrees */
  transform-origin: center; /* Ensures the rotation is centered */
}
.grid-day.available {
  cursor: pointer;
  background-color: var(--bg-brand-secondary);
  color: var(--text-brand);
  font-weight: 600;
}
.grid-day.danger {
  background-color: var(--bg-error-secondary);
  color: var(--text-error);
}
.grid-day.warning {
  background-color: var(--bg-warning-secondary);
  color: var(--text-warning);
}
.grid-day.success {
  background-color: var(--bg-success-secondary);
  color: var(--text-success);
}
.grid-day.selected {
  background-color: var(--fg-brand);
  color: white;
  font-weight: 600;
}
.grid-day.today::after {
  content: '';
  height: 4px;
  width: 4px;
  position: absolute;
  bottom: 5px;
  left: calc(50% - 2px);
  background-color: var(--text-secondary);
  border-radius: 4px;
}

.time-slots {
  width: 100%;
  padding-right: 5px;
}
.compact .time-slots {
  min-width: 190px;
}

.time-slots_tiled > .time-slots-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  gap: var(--s-2);
}

.time-slot-back-btn {
  display: inline-flex;
  gap: var(--s-2);
  margin-bottom: var(--s-2);
  padding: var(--s-2) var(--s-4) var(--s-2) 0;
  cursor: pointer;
  color: var(--text-secondary);
}
.compact .time-slot-back-btn {
  padding: var(--s-1) var(--s-2) var(--s-1) 0;
  gap: var(--s-1);
}
.time-slot-back-btn:hover {
  color: var(--fg-brand);
}

.time-slots-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--s-2);
  max-height: 300px;
  overflow: auto;
}
.compact .time-slots-wrapper {
  width: 190px;
  gap: var(--s-1);
}
.time-slot {
  overflow: hidden;
  white-space: nowrap;
}
.time-slot .time {
  border: 1px solid var(--border-primary);
}
.time-slot .time {
  box-sizing: border-box;
  border-radius: var(--rounded-md);
  height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--s-4) var(--s-4);
  white-space: nowrap;
}
.compact .time-slot .time,
.compact .time-slot .confirm-btn {
  height: 2.2rem;
  padding: var(--s-2) var(--s-4);
}

.time-slot .time {
  transition: all 0.2s;
  width: 100%;
}
.time-slot:not(.selected) .time {
  cursor: pointer;
}

.time-slot .time.disabled {
  cursor: not-allowed;
  text-decoration: none;
  border-color: transparent;
  color: var(--text-quinary);
  text-decoration: line-through;
}
.time-slot:not(.selected) > .time:not(.disabled).time:hover {
  background-color: var(--bg-tertiary);
}
.time-slot.selected .time {
  border-color: var(--border-brand);
  color: var(--text-brand);
  background-color: var(--bg-brand);
  font-weight: 600;
}
</style>
