//TODO: add skeleton whilst formData is being fetched
<template>
  <!-- <div class="flex flex-col items-center" :style="{ '--manufacturer-color': '#0057a7' }"> -->
  <div class="flex flex-col items-center">
    <div v-if="formData.dealership.id" class="px-4 w-full sm:w-[640px]">
      <!-- Header -->
      <div class="w-full flex flex-col items-center p-4">
        <div class="mb-8 mt-8 flex justify-center">
          <img class="w-full max-w-[70px] max-h-[50px]" :src="formData.dealership.manufacturerLogoUrl" alt="Logo" />
        </div>

        <div class="flex flex-col items-center justify-center">
          <div class="text-3xl font-semibold">Book an appointment</div>
          <div class="text-lg text-quaternary">{{ formData.dealership.name }}</div>
        </div>
      </div>

      <Alert
        v-if="formData.existingAppointments.length"
        title="Upcoming appointment"
        :message="
          'It looks like you already have an appointment booked in for:<br> <b>' +
          dayjs(formData.existingAppointments[0].time).format('ddd Do MMM [at] HH:mm') +
          '</b>'
        "
        severity="warning"
        :show-dismiss-button="false"
        :show-close-button="false"
        :action-button="true"
        action-button-text="View/Manage Appointment"
        @action="viewExistingAppointment(formData.existingAppointments[0].id)"
      />

      <!-- Main Body Form -->
      <div class="mt-8 grid gap-10">
        <!-- Current Intentions -->
        <div>
          <div class="text-lg font-semibold mb-3">
            <span>Current Intentions *</span>
          </div>
          <RadioBoxed
            v-model="state.currentIntentions"
            :options="formOptions.intentions"
            :class="{ 'has-error': v$.currentIntentions.$error }"
            class="flex-col xs:flex-row"
            :equal-widths="false"
          />
          <InputError :has-error="v$.currentIntentions.$error">{{ v$.currentIntentions.$errors[0]?.$message }}</InputError>
        </div>

        <!-- Preferred Staff -->
        <div>
          <div class="text-lg font-semibold mb-3">
            <span>Preferred Staff</span>
          </div>
          <Select
            v-model="state.preferredStaff"
            :options="formOptions.staff"
            searchable
            clearable
            placeholder="Anyone"
            :class="{ 'has-error': v$.preferredStaff.$error }"
          >
            <template #value="{ value }">
              <div class="flex gap-2">
                <Avatar size="xxs" :url="value.avatarUrl" icon="user" />
                <span>{{ value.label }}</span>
              </div>
            </template>
            <template #option="{ option }">
              <div class="flex gap-2">
                <Avatar size="xxs" :url="option.avatarUrl" icon="user" />
                <span>{{ option.label }}</span>
              </div>
            </template>
          </Select>
          <InputError :has-error="v$.preferredStaff.$error">{{ v$.preferredStaff.$errors[0]?.$message }}</InputError>
        </div>

        <!-- Methods -->
        <div>
          <div class="text-lg font-semibold mb-3">
            <span>Method *</span>
          </div>
          <RadioBoxed
            v-model="state.method"
            :options="formOptions.methods"
            :class="{ 'has-error': v$.method.$error }"
            class="flex-col xs:flex-row"
          />
          <InputError :has-error="v$.method.$error">{{ v$.method.$errors[0]?.$message }}</InputError>
        </div>

        <!-- Date & Time -->
        <div>
          <div class="text-lg font-semibold mb-3">
            <span>Date & Time *</span>
          </div>
          <AppointmentPicker
            v-model="state.dateTime"
            v-model:available-dates="filteredAvailability"
            display-style="inline"
            :traffic-light="false"
            :compact="false"
            @date-range-updated="onDateRangeUpdated"
            :class="{ 'has-error': v$.dateTime.$error }"
            class="flex-col gap-6 items-center xs:flex-row xs:items-start"
          />
          <InputError :has-error="v$.dateTime.$error">{{ v$.dateTime.$errors[0]?.$message }}</InputError>
        </div>

        <!-- Additional Notes -->
        <div class="mb-10">
          <div class="text-lg font-semibold mb-3">
            <span>Additional Notes</span>
          </div>
          <TextArea
            v-model="state.note"
            placeholder="Enter any additional notes here"
            class="w-full"
            :class="{ 'has-error': v$.note.$error }"
          />
        </div>

        <!-- Summary 'String'. E.g. Booking a video appointment on the 12th Dec -->
        <div v-if="state.method && state.dateTime">
          <div class="text-lg text-center">
            <span v-if="state.method">Booking a {{ state.method }} appointment</span>
            <span v-if="state.dateTime">
              for the {{ dayjs(state.dateTime).format('Do MMM YYYY') }} at {{ dayjs(state.dateTime).format('HH:mm') }}</span
            >
          </div>
        </div>

        <div v-if="v$.$error" class="text-error">Some required fields are missing</div>
        <div class="flex justify-center mb-10">
          <Button label="Book Appointment" :is-loading="formIsSubmitting" @click="submitForm" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import AppointmentPicker from '@/components/AppointmentPicker.vue'
import RadioBoxed from '@/components/forms/RadioBoxed.vue'
import Button from '@/components/button/Button.vue'
import Icon from '@/components/icon/Icon.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import TextArea from '@/components/forms/TextArea.vue'
import Select from '@/components/forms/Select.vue'
import InputError from '@/components/forms/InputError.vue'

import Swal from 'sweetalert2'
import dayjs from 'dayjs'

import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Alert from '@/components/alert/Alert.vue'

const route = useRoute()
const router = useRouter()

const formData = ref({
  existingAppointments: [],
  dealership: {
    id: null,
    name: null,
    address: {
      line1: null,
      line2: null,
      city: null,
      county: null,
      postcode: null,
      country: null
    },
    phone: null,
    website: null,
    groupName: null,
    groupLogoUrl: null,
    manufacturerName: null,
    manufacturerLogoUrl: null,
    manufacturerBrandColor: null
  },
  assignableUsers: []
})

const state = ref({
  currentIntentions: null,
  preferredStaff: null,
  method: 'physical',
  dateTime: null,
  note: null
})

const availableDates = ref([])
const dateRangeStart = ref('')
const dateRangeEnd = ref('')

const formOptions = ref({
  intentions: [
    { value: 'Part-Exchange', label: 'Part-Exchange', disabled: false },
    { value: 'Refinance', label: 'Refinance', disabled: false },
    { value: 'Settle', label: 'Settle', disabled: false },
    { value: 'Hand Back', label: 'Hand Back', disabled: false },
    { value: 'Buy-Back', label: 'Buy-Back', disabled: false },
    { value: 'Unsure', label: 'Unsure', disabled: false }
  ],
  staff: [],
  methods: [
    {
      value: 'physical',
      label: 'Dealership',
      icon: 'location-dot',
      disabled: false
    },
    {
      value: 'phone',
      label: 'Phone',
      icon: 'phone',
      disabled: false
    },
    {
      value: 'video',
      label: 'Video',
      icon: 'video',
      disabled: false
    }
  ]
})

function fetchFormData(id) {
  if (typeof id === 'number' && id > 0) {
    try {
      axios
        .get(`${import.meta.env.VITE_URL_API}/v1/appointments/public-booking-page?agId=${id}&api_key=8lodj2JhqhkXLKdumzobJlJQvah38n12`)
        .then(response => {
          if (response.data.success) {
            formData.value = response.data.data

            formOptions.value.staff = response.data.data.assignableUsers.map(user => {
              return {
                value: user.id,
                label: user.forename + ' ' + user.surname,
                avatarUrl: user.profilePicUrl,
                disabled: false
              }
            })
          } else {
            // TODO: Handle error
          }
        })
    } catch (error) {
      //TODO: Handle error
    }
  } else {
    //TODO: Handle error
  }
}

if (route.params.agreementId) {
  fetchFormData(Number(atob(route.params.agreementId)))
}

function fetchAppAvailability(id, startDate, endDate) {
  if (typeof id === 'number' && id > 0 && typeof startDate === 'string' && startDate && typeof endDate === 'string' && endDate) {
    try {
      axios
        .get(`${import.meta.env.VITE_URL_API}/v1/availability?api_key=8lodj2JhqhkXLKdumzobJlJQvah38n12`, {
          params: {
            dealershipId: id,
            startDate: startDate,
            endDate: endDate
          }
        })
        .then(response => {
          if (response.data.success) {
            let responseData = response.data.data
            availableDates.value = responseData
          } else {
            console.error(response)
          }
        })
    } catch (error) {
      console.error(`The request failed: ${error.message}`)
    }
  } else {
    console.error('Invalid params')
  }
}

const filteredAvailability = computed(() => {
  let method = state.value.method
  // return a version of availableDatesArray that is filtered based on the method selected.
  if (!availableDates.value.length) {
    return []
  }

  let availableDatesFiltered = availableDates.value.reduce((filteredDates, day) => {
    // Check if the day's methods include the desired method
    if (day.availableMethods[method]) {
      // Filter the slots directly and add the day to the result if it has valid slots

      const filteredSlots = day.slots.filter(
        slot =>
          slot.methodsAllowed[method] &&
          dayjs()
            .add(48, 'hour')
            .isBefore(dayjs(`${dayjs(day.date).format('YYYY-MM-DD')}T${slot.timeStart}Z`))
      )
      if (filteredSlots.length > 0) {
        filteredDates.push({ ...day, slots: filteredSlots })
      }
    }
    return filteredDates
  }, [])
  return availableDatesFiltered
})

function onDateRangeUpdated(payload) {
  dateRangeStart.value = payload.start
  dateRangeEnd.value = payload.end

  if (formData.value.dealership?.id) {
    fetchAppAvailability(Number(atob(formData.value.dealership.id)), dateRangeStart.value, dateRangeEnd.value)
  }
}

// On method change, reset the date and time
watch(
  () => state.value.method,
  () => {
    if (formData.value.dealership?.id) {
      fetchAppAvailability(Number(atob(formData.value.dealership.id)), dateRangeStart.value, dateRangeEnd.value)
    }

    state.value.dateTime = null
  }
)

// Watch for changes to manufacturerBrandColor and update :root dynamically
watch(
  () => formData.value.dealership.manufacturerBrandColor,
  newColor => {
    if (newColor) {
      document.documentElement.style.setProperty('--manufacturer-color', newColor)
    }
  },
  { immediate: true } // Run this watcher immediately
)

function viewExistingAppointment(id) {
  router.push(`/appointment/${id}`)
}

const vuelidateRules = {
  currentIntentions: { required },
  preferredStaff: {},
  method: { required },
  dateTime: { required },
  note: {}
}

const v$ = useVuelidate(vuelidateRules, state)
const formIsSubmitting = ref(false)

function submitForm() {
  v$.value.$validate()

  if (v$.value.$error) {
    console.log('Form is invalid')
  } else {
    try {
      formIsSubmitting.value = true

      let dataToPost = {
        agreementId: Number(atob(route.params.agreementId)),
        type: 0,
        intent: state.value.currentIntentions,
        method: state.value.method,
        time: state.value.dateTime,
        timeZone: formData.value.dealership.timeZone,
        note: state.value.note || 'Booked by customer. No note left',
        dealershipId: Number(atob(formData.value.dealership.id)),
        requestedUserToAssign: state.value.preferredStaff,
        createdBySource: 'customer',
        createdByType: null,
        createdBy: null,
        notifyCustomer: true,
        notifyDealership: true,
        sourceType: 'appointment_invite', // Retain App, Appointment Invite, AI, Server
        sourceId: Number(atob(route.query?.inviteId)), // EG: Invite ID
        communicationType: route.query?.commType || null, // Phone, Email, SMS
        communicationId: Number(atob(route.query?.commId)) || null // Eg: ID of call, email, sms
      }

      axios.post(`${import.meta.env.VITE_URL_API}/v1/appointments?api_key=8lodj2JhqhkXLKdumzobJlJQvah38n12`, dataToPost).then(response => {
        if (response.data.success) {
          Swal.fire({
            title: 'Success',
            text: 'Your appointment has now been booked in',
            icon: 'success',
            confirmButtonText: 'View Appointment',
            confirmButtonColor: 'var(--manufacturer-color)',
            allowOutsideClick: false
          }).then(result => {
            if (result.isConfirmed) {
              // Redirect to appointments page
              viewExistingAppointment(btoa(response.data.data.id))
            }
          })
        } else {
          console.error(`Failed to book appointment`)
        }
        formIsSubmitting.value = false
      })
    } catch (error) {
      console.error(`The request failed: ${error.message}`)
      formIsSubmitting.value = false
    }
  }
}
</script>

<style>
/* Override brand colors */
:root {
  --brand-50: color-mix(in srgb, var(--manufacturer-color) 5%, white 95%);
  --brand-100: color-mix(in srgb, var(--manufacturer-color) 10%, white 90%);
  --brand-200: color-mix(in srgb, var(--manufacturer-color) 20%, white 80%);
  --brand-300: color-mix(in srgb, var(--manufacturer-color) 30%, white 70%);
  --brand-400: color-mix(in srgb, var(--manufacturer-color) 40%, white 60%);
  --brand-500: var(--manufacturer-color);
  --brand-600: color-mix(in srgb, var(--manufacturer-color) 60%, white 40%);
  --brand-700: color-mix(in srgb, var(--manufacturer-color) 70%, white 30%);
  --brand-800: color-mix(in srgb, var(--manufacturer-color) 80%, white 20%);
  --brand-900: color-mix(in srgb, var(--manufacturer-color) 90%, white 10%);

  --bg-brand: var(--brand-50);
  --bg-brand-secondary: var(--brand-100);

  --fg-brand: var(--manufacturer-color);

  --border-brand: var(--brand-300);
  --focus-ring-brand: var(--brand-600);
}
</style>
