<template>
  <div class="relative">
    <Icon v-if="icon" class="textarea-icon" :class="['textarea-icon-' + iconPosition]" :type="icon" />
    <SpeechToText v-if="hasSpeechToText" class="textarea-stt" @transcript-change="sttTranscriptChange" />
    <textarea
      :id="id"
      ref="textAreaElement"
      :placeholder="placeholder"
      :rows="rows"
      class="form-control w-full relative"
      :value="modelValue"
      :class="{
        'has-error': hasError,
        'resize-none': autoResize,
        'textarea-with-icon': icon,
        'textarea-with-stt': hasSpeechToText,
        'textarea-no-border': !hasBorder
      }"
      :iconPosition="iconPosition"
      :spellcheck="spellCheck"
      @input="updateValue"
    ></textarea>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

import Icon from '@/components/icon/Icon.vue'
import SpeechToText from '@/components/speech-to-text/SpeechToText.vue'

export default {
  components: {
    SpeechToText,
    Icon
  },
  props: {
    placeholder: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    modelValue: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    iconPosition: {
      type: String,
      default: 'left'
    },
    rows: {
      type: [Number, String],
      default: 5
    },
    maxHeight: {
      type: Number,
      default: 150
    },
    autoResize: {
      type: Boolean,
      default: false
    },
    hasSpeechToText: {
      type: Boolean,
      default: false
    },
    hasBorder: {
      type: Boolean,
      default: true
    },
    spellCheck: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const textAreaElement = ref(null)
    const updateValue = event => {
      context.emit('update:modelValue', event.target.value)
    }

    const resize = () => {
      textAreaElement.value.style.height = ''
      textAreaElement.value.style.height = textAreaElement.value.scrollHeight + 'px'

      //Remove scroll bar if height is less than maxHeight prop
      if (textAreaElement.value.scrollHeight >= props.maxHeight) {
        textAreaElement.value.style.overflow = 'auto'
      } else {
        textAreaElement.value.style.overflow = 'hidden'
      }
    }

    watch(
      () => props.modelValue,
      () => {
        if (props.autoResize) {
          resize()
        }
      }
    )

    function sttTranscriptChange(e) {
      context.emit('update:modelValue', e)
    }

    return { textAreaElement, updateValue, resize, sttTranscriptChange }
  }
}
</script>

<style>
.textarea-with-icon {
  position: relative;
}

.textarea-icon {
  color: var(--text-secondary);
  position: absolute;
  z-index: 2;
}

.textarea-icon-left {
  float: left;
  margin-left: 12px;
  top: 8px;
}

.textarea-icon-right {
  float: right;
  margin-left: 12px;
  top: 8px;
}

.textarea-with-icon[iconPosition='left'] {
  padding-left: 35px !important;
}

.textarea-with-icon[iconPosition='right'] {
  padding-right: 35px !important;
}

.textarea-with-stt {
  padding-right: 50px !important;
}
.textarea-stt {
  position: absolute;
  z-index: 2;
  float: right;
  right: 15px;
  top: 5px;
}
.textarea-no-border {
  border: none;
}
</style>
