<template>
  <div class="speech-to-text-wrapper" :class="{ recording: isRecording }">
    <Icon :type="isRecording ? 'stop' : 'microphone'" class="text-lg cursor-pointer" fa-style="fas" @click="toggleMic" />
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'

import { ref, onMounted, watch } from 'vue'

export default {
  components: {
    Icon
  },
  props: {
    transcript: String
  },
  setup(props, context) {
    const transcript = ref('')
    const isRecording = ref(false)

    const Recognition = window.SpeechRecognition || window.webkitSpeechRecognition
    const sr = new Recognition()

    onMounted(() => {
      sr.continuous = true
      sr.interimResults = true

      sr.onstart = () => {
        isRecording.value = true
      }

      // sr.start()

      sr.onresult = evt => {
        const t = Array.from(evt.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join('')

        transcript.value = t
      }

      sr.onend = () => {
        isRecording.value = false

        //Check for keywords and replace
        // if (transcript.value.includes("the customer")) {
        //     transcript.value = transcript.value.replace("the customer", "[CUSTOMER NAME]")
        // }
        if (transcript.value.includes(' full stop')) {
          transcript.value = transcript.value.replace(' full stop', '.')
        }
      }
    })

    const toggleMic = () => {
      if (isRecording.value) {
        sr.stop()
      } else {
        sr.start()
      }
    }

    watch(
      () => transcript.value,
      (first, second) => {
        context.emit('transcriptChange', transcript.value)
      }
    )
    return { toggleMic, isRecording, transcript }
  }
}
</script>

<style>
.speech-to-text-wrapper {
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg-tertiary);
  border-radius: 100px;
}
.speech-to-text-wrapper:hover {
  background-color: var(--fg-brand);
  color: white;
}
.speech-to-text-wrapper.recording {
  background-color: var(--fg-error);
  color: white;
}
</style>
