<template>
  <div class="badge-wrapper">
    <slot />
    <div
      v-if="content"
      class="badge"
      :class="[
        'badge-' + variant,
        { 'badge-inline': inline, 'badge-rounded': rounded, 'badge-bordered': bordered },
        position,
        'badge-' + size
      ]"
    >
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    content: {
      type: [String, Number],
      default: null
    },
    variant: {
      type: String,
      default: 'primary'
    },
    rounded: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md'
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  setup() {}
}
</script>

<style>
.badge-wrapper {
  position: relative;
  display: inline-block;
  /* display: flex; */
}
.badge {
  left: calc(100% - 12px);
  bottom: calc(100% - 12px);

  color: white;

  border: 1px solid transparent;
  border-radius: 0.358rem;
  font-size: 85%;
  vertical-align: baseline;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
  text-indent: 0;

  min-width: 20px;
  height: 1.25rem;
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  z-index: 1;

  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    background 0s,
    border 0s,
    -webkit-box-shadow 0.15s ease-in-out;
  -webkit-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    background 0s,
    border 0s,
    -webkit-box-shadow 0.15s ease-in-out;
}
.badge.badge-inline {
  position: relative;
  padding: 10px 6px;
}

.badge-rounded {
  border-radius: 100px;
}

.badge-bordered {
  border: 2px solid white;
}

.dark .badge-bordered {
  border: 2px solid var(--darkmode-3);
}

.avatar .badge {
  top: 0px;
  right: 0px;
  font-size: 0.7rem;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon .badge {
  top: -8px;
  right: -11px;
  /* font-size: 0.7rem; */
  height: 1.3rem;
  min-width: 1rem;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-xs {
  padding: 0px 0px;
  font-size: 0.6rem;
  min-width: 0px;
}

.badge-sm {
  padding: 1px 5px;
  font-size: 0.7rem;
  min-width: 10px;
  line-height: 14px;
}

.badge-md {
  padding: 8px 3px;
  line-height: 15px;
  font-size: 0.8rem;
  min-width: 18px;
}

.badge-primary {
  background-color: var(--bg-brand-solid);
}

.badge-secondary {
  background-color: var(--text-secondary);
  color: var(--white);
}

.badge-light {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
}

.dark .badge-light {
  background-color: var(--darkmode-3);
}

.badge-extra-light {
  background-color: var(--extra-light);
  color: var(--text-secondary);
}

.dark .badge-extra-light {
  background-color: var(--darkmode-3);
}

.badge-warning {
  background-color: var(--bg-warning-solid);
  color: white;
}

.badge-danger {
  background-color: var(--bg-error-solid);
  color: white;
}

.badge-success {
  background-color: var(--bg-success-solid);
  color: white;
}

.badge-info {
  background-color: var(--bg-info-solid);
  color: white;
}
</style>
