import { onMounted, onUnmounted, reactive, toRefs } from 'vue'

const sizes = reactive({
  browserWidth: window.innerWidth,
  deviceWidth: screen.width,
  isMobile: false,
  size: 'xs'
})

const screenSizeString = () => {
  let string = ''
  if (window.innerWidth <= 640) {
    string = 'xs'
  } else if (window.innerWidth > 640 && window.innerWidth <= 768) {
    string = 'sm'
  } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
    string = 'md'
  } else if (window.innerWidth > 1024 && window.innerWidth <= 1280) {
    string = 'lg'
  } else if (window.innerWidth > 1280) {
    string = 'xl'
  }
  return string
}

const isMobile = () => {
  return window.innerWidth <= 640
}

function updateSizes() {
  sizes.browserWidth = window.innerWidth
  sizes.deviceWidth = screen.width
  sizes.isMobile = isMobile()
  sizes.size = screenSizeString()
}

// Automatically initialize window size tracking by directly setting up listeners
function setupEventListeners() {
  updateSizes() // Initial update

  window.addEventListener('resize', updateSizes)

  onUnmounted(() => {
    window.removeEventListener('resize', updateSizes)
  })
}

// Automatically initialize window size tracking
setupEventListeners()

export function useWindowSize() {
  return {
    ...toRefs(sizes)
  }
}

export function useBreakPoints(breakpoints = {}) {
  // Example breakpoint obj: { xs: 'horizontal', sm: 'vertical', md: 'vertical', lg: 'horizontal' }

  // Check that the passed argument is an object
  if (typeof breakpoints !== 'object') {
    throw new Error('The argument in useBreakPoints must be an object')
  }

  // Check that the key 'xs' exists in the object
  if (!breakpoints.xs) {
    throw new Error('The argument in useBreakPoints must contain a key of "xs" as the base break point')
  }

  let screenSizeMapping = {
    xs: 640,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1920
  }

  // Loop thorugh the mapping, create a new object with the same keys, but with the values from the breakpoints object
  // Keep track of latest breakpoint value
  let latestBreakpointKey = null
  for (let key in screenSizeMapping) {
    // If the key doesn't exist in the breakpoints object, use the latest breakpoint value
    if (breakpoints[key]) {
      screenSizeMapping[key] = breakpoints[key]
      latestBreakpointKey = key
    } else {
      screenSizeMapping[key] = screenSizeMapping[latestBreakpointKey]
    }
  }

  // Example mapping: { "xs": "horizontal", "sm": "horizontal", "md": "vertical", "lg": "horizontal", "xl": "horizontal" }
  return screenSizeMapping[sizes.size]
}
