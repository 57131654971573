<template>
  <div class="flex flex-col items-center justify-center w-full" :style="`height: ${innerHeight}px`">
    <div class="text-4xl mb-6 text-center font-semibold text-primary">404</div>
    <div class="text-3xl text-center font-bold mb-4">Page not found</div>
    <div class="mb-10 text-center px-2">Sorry, we couldn’t find the page you’re looking for.</div>
  </div>
</template>

<script>
import Button from '@/components/button/Button.vue'
import Icon from '@/components/icon/Icon.vue'
import { ref } from 'vue'

export default {
  components: { Button, Icon },
  setup() {
    const innerHeight = ref(window.innerHeight)
    return { innerHeight }
  }
}
</script>

<style></style>
