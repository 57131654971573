<template>
  <div class="p-2 max-w-[800px] m-auto">
    <FileUpload
      :header-title="null"
      :api-endpoint="uploadImagesApiEndpoint"
      :api-post-data="{ uploadedBy: null, uploadedByType: 'customer' }"
      button-position="full-width"
      :accepted-file-types="['image/jpeg', 'image/png']"
      :maxFileSize="10000000"
      @uploaded="$nextTick(() => fetchValuation(valuationId))"
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'

import FileUpload from '@/components/file-upload/FileUpload.vue'
const route = useRoute()

const valuationId = route.params.vid
const uploadImagesApiEndpoint = ref(
  `https://api.retainauto.com/v1/valuations/${valuationId}/upload-images?api_key=8lodj2JhqhkXLKdumzobJlJQvah38n12`
)

const state = ref(null)

// Fetch valuation data from API
async function fetchValuation(id) {
  return new Promise((resolve, reject) => {
    if (id) {
      try {
        axios.get(`https://api.retainauto.com/v1/valuations/${id}?api_key=8lodj2JhqhkXLKdumzobJlJQvah38n12`).then(response => {
          if (response.data.response.status == 'success') {
            state.value = response.data.data
            resolve()
          } else {
            console.error(response.data.response.message)
            reject()
          }
        })
      } catch (error) {
        console.error(`The request failed: ${error.message}`)
        reject()
      }
    } else {
      console.error("Couldn't load content - invalid or missing valuation ID")
      reject()
    }
  })
}

onMounted(() => {
  fetchValuation(valuationId)
})
</script>
