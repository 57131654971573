import packageInfo from '/package.json'

let currentEnv = import.meta.env.MODE
let currentVersion = packageInfo.version
console.log('v' + currentVersion)

import { createApp } from 'vue'

import './assets/css/tailwind.css'

import './assets/css/main.css' // INFO override ALL stylesheets

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import isToday from 'dayjs/plugin/isToday'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekday from 'dayjs/plugin/weekday'
import isoWeek from 'dayjs/plugin/isoWeek'
import isBetween from 'dayjs/plugin/isBetween'
import duration from 'dayjs/plugin/duration'
import calendar from 'dayjs/plugin/calendar'

import VueTippy from 'vue-tippy'
import VueClickAway from 'vue3-click-away'

import 'tippy.js/dist/tippy.css' // optional for styling
import 'tippy.js/dist/backdrop.css' // optional for animation
import 'tippy.js/animations/perspective.css' // optional for animation
import 'tippy.js/themes/light.css'
import './assets/css/tippy.css' //Custom themes

import 'sweetalert2/dist/sweetalert2.min.css'

import App from './App.vue'
import router from './router'

const app = createApp(App)

// app.use(VueTippy)
app.use(
  VueTippy,
  // optional
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      allowHTML: true,
      placement: 'top',
      delay: 300,
      theme: 'light'
    } // => Global default options * see all props
  }
)
app.use(VueClickAway)
app.use(dayjs)

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(isToday)
dayjs.extend(weekOfYear)
dayjs.extend(weekday)
dayjs.extend(isoWeek)
dayjs.extend(isBetween)
dayjs.extend(duration)
dayjs.extend(calendar)

app.mixin({
  methods: {
    dayjs: dayjs
  }
})

app.use(router)
app.mount('#app')
