<template>
  <div class="icon">
    <Badge rounded :size="badgeSize" :content="badgeContent">
      <div :style="'color: var(--text-' + severity + ')!important'">
        <i v-if="featured" class="far fa-fw fa-circle absolute mt-[3.2px] scale-[1.8] opacity-15" />
        <i v-if="featured" class="fal fa-fw fa-circle absolute mt-[3.2px] scale-[2.8] opacity-5" />
        <i :class="[{ 'fa-spin': spinner, 'fa-pulse': pulse }, faStyle, 'fa-fw fa-' + iconType]"></i>
      </div>
    </Badge>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import Badge from '@/components/badge/Badge.vue'

const props = defineProps({
  type: {
    type: String,
    default: null
  },
  iconStyle: {
    // TODO: deprecate in favour of severity
    type: String,
    default: ''
  },
  severity: {
    type: String,
    default: ''
  },
  badgeContent: {
    type: String,
    default: ''
  },
  spinner: {
    type: Boolean,
    default: false
  },
  pulse: {
    type: Boolean,
    default: false
  },
  faStyle: {
    type: String,
    default: 'fad'
  },
  badgeSize: {
    type: String,
    default: 'md'
  },
  featured: {
    type: Boolean,
    default: false
  }
})

const iconType = ref(props.type)

// Watch for changes to type
watch(
  () => props.type,
  newVal => {
    if (newVal) {
      iconType.value = newVal
    }
  },
  { immediate: true }
)
</script>

<style>
.icon {
  display: inline-flex;
  position: relative;
}
</style>
